import { Backdrop, CircularProgress, Grid, Paper } from "@mui/material";
import Steppers from "./Steppers";
import { useEffect, useRef, useState } from 'react';
import Step0 from './Step0';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from "./Step3";
import Step4 from "./Step4";
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from "react-router-dom";
import { get_data, post_data } from "../../api";
import { useSelector } from "react-redux";
import { useRazorpay } from 'react-razorpay';
import { primaryColor } from "../../constant";
import SuccessModal from "./SuccessModal";


export default function MultiStepForm({ setStep, step }) {

    const { user_data } = useSelector(state => state.user);
    const theme = useTheme();
    const matches_md = useMediaQuery(theme.breakpoints.down('md'))
    const matches_sm = useMediaQuery(theme.breakpoints.down('sm'))

    const [formData, setFormData] = useState({});
    const [successOpen, setSuccessOpen] = useState(false)
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [gender, setGender] = useState('')
    const [companyName, setCompanyName] = useState('');
    const [companyIndustry, setCompanyIndustry] = useState('');
    const [website, setWebsite] = useState('');
    const [businessDescription, setBusinessDescription] = useState('');
    const [designator, setDesignator] = useState('')
    const [members, setMembers] = useState([{ id: Date.now(), firstName: '', lastName: '', roleInCompany: '', phoneNumber: '', address: '', ssn_Number: '', errors: {}, passport: '', responsible_member: false, file: '' }]);
    const [selectedAddons, setSelectedAddons] = useState([]);
    const [totalAddonPrice, setTotalAddonPrice] = useState(0);
    const [loading, setLoading] = useState(false);
    const [services, setServices] = useState([]);
    const planAmount = JSON.parse(localStorage?.getItem("selectedPlanAmount"));
    const [discount, setDiscount] = useState(0);
    const [couponApplied, setCouponApplied] = useState(false);
    const [isResponsible, setIsResponsible] = useState(false);

    const handleNext = (data) => {

        setFormData((prev) => ({ ...prev, ...data }));
        setStep((prev) => prev + 1);
    };

    const handlePrev = () => {
        setStep((prev) => prev - 1);
    };

    const handleSubmit = (data) => {
        setFormData((prev) => ({ ...prev, ...data }));
        let payload = { ...formData, ...data }
        // if (payload) {
        //     setFinalFormData(payload)
        // }
        setLoading(true)
        handlePhonePePayment(payload)
    };


    const [exchangeRate, setExchangeRate] = useState(null);

    useEffect(() => {
        const fetchExchangeRate = async () => {
            try {
                const response = await get_data(`user/get-exchange-rate`);
                if (response?.status) {
                    setExchangeRate(response?.data);
                } else {
                }
            } catch (error) {
            }
        };
        fetchExchangeRate();
    }, []);


    const convertToINR = (usdAmount) => {
        return (usdAmount * exchangeRate).toFixed(2);
    };



    const handlePhonePePayment = async (payload) => {

        handleCreateCompany(payload)

        let phonePePayload = {
            user_id: user_data?._id,
            name: user_data?.first_name + ' ' + user_data?.last_name,
            // amount: 1,
            amount: convertToINR(Math?.ceil(JSON.parse(localStorage?.getItem("selectedPlanAmount")) + totalAddonPrice - discount)),
            number: user_data?.phone,
            transactionId: `TID${Date.now()}${Math?.floor(Math?.random() * 1000)}`,
        }
        const response = await post_data('company/make-payment', phonePePayload)

        if (response) {
            window.location.href = response?.data?.url;
            setLoading(false)

        } else {
            alert("Failed to initiate PhonePe payment.");
            setLoading(false)
        }
    }


    const handleCreateCompany = async (formData) => {
        try {

            const { name, email, phone, gender, companyName, designator, companyIndustry, website, businessDescription, selectedAddons } = formData;

            const memberDetails = members?.map((member, index) => {
                return {
                    id: member?.id,
                    first_name: member?.firstName,
                    last_name: member?.lastName,
                    phone: member?.phoneNumber,
                    role: member?.roleInCompany,
                    address: member?.address,
                    responsible_member: member?.responsible_member,
                    passport: member?.passport
                }
            })

            const selectedAddonsArray = services?.map((addon) => {
                return {
                    name: addon?.text,
                    amount: addon?.amt,
                }
            })

            localStorage.setItem('companyFormdata', JSON.stringify({
                name: name,
                email: email,
                phone: phone,
                gender: gender,
                company_name: companyName,
                designator: designator,
                industry: companyIndustry,
                website: website,
                description: businessDescription,
                members: memberDetails,
                addons: selectedAddonsArray,
                addons_amount: totalAddonPrice,
                discount_amount: discount,
                services: services,
            }))

            sessionStorage.setItem('members', JSON.stringify(members));


        } catch (e) {
            // console.log(e);
        }
    };

    return (
        <>
            {loading &&
                <Backdrop
                    sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
                    open={loading}
                    onClick={setLoading(false)}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            }
            <SuccessModal open={successOpen} setOpen={setSuccessOpen} />
            <div style={{ padding: matches_md ? '0 2%' : '0 10%', marginTop: '4%' }} >
                <Grid container spcing={2} style={{ marginTop: '0' }} gap={2}>
                    {/* <Grid item xs={12} md={3.3}>
                        <div style={{ padding: 20 }}>
                            <Steppers step={step} />
                        </div>
                    </Grid> */}
                    <Grid item xs={12}>
                        <div style={{ padding: matches_md ? 0 : 20 }}>
                            {step === 0 &&
                                <Step0 name={name} setName={setName} email={email} setEmail={setEmail} phone={phone} setPhone={setPhone} gender={gender} setGender={setGender} onNext={handleNext} />
                            }
                            {step === 1 && (
                                <Step1 companyName={companyName} setCompanyName={setCompanyName} companyIndustry={companyIndustry} setCompanyIndustry={setCompanyIndustry} website={website} setWebsite={setWebsite} businessDescription={businessDescription} setBusinessDescription={setBusinessDescription} designator={designator} setDesignator={setDesignator} onNext={handleNext} onPrev={handlePrev} />
                            )}
                            {step === 2 && (
                                <Step2 members={members} setMembers={setMembers} onPrev={handlePrev}
                                    onNext={handleNext}
                                    isResponsible={isResponsible}
                                    setIsResponsible={setIsResponsible}
                                />
                            )}
                            {step === 3 && (
                                <Step3 selectedAddons={selectedAddons} setSelectedAddons={setSelectedAddons} totalAddonPrice={totalAddonPrice} setTotalAddonPrice={setTotalAddonPrice} services={services} setServices={setServices} onNext={handleNext} onPrev={handlePrev} />
                            )}
                            {step === 4 && (
                                <Step4 totalAddonPrice={totalAddonPrice} setSelectedAddons={setSelectedAddons} setTotalAddonPrice={setTotalAddonPrice} services={services} setServices={setServices} onSubmit={handleSubmit} onPrev={handlePrev} loading={loading} discount={discount} setDiscount={setDiscount} couponApplied={couponApplied} setCouponApplied={setCouponApplied} />
                            )}
                        </div>
                    </Grid>
                </Grid>
            </div>
        </>
    )
}
